import(/* webpackMode: "eager", webpackExports: ["CookieProvider"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/(Contexts)/CookieContextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/(Contexts)/RouteTrackerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/(RouteBlockers)/AuthorizationWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/CookieModal/CookieModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/GTMTrackers/GTMProvider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/LoginOrSignUpModal/FeatureFlagStoreInitaitor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/NavBar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/globalRedux/provider.jsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/hooks/useViewport.ts");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/mobile-responsive.css");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/styles/react-select-styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
