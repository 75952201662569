'use client'
import { RootState } from '@/app/globalRedux/store'
import { sendGTMEvent } from '@next/third-parties/google'
import { useParams, usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const GTMPageTracker = () => {
  const pathname = usePathname()
  const params = useParams()
  const { userSubscription, userId } = useSelector(
    (state: RootState) => state.user
  )
  useEffect(() => {
    sendGTMEvent({
      event: 'page_view',
      current_day: new Date().getDay(),
      page_lang: params?.lng ?? 'en',
      current_user_id: `${userId ? userId : 'non_logged_in'}`,
      user_subscription: `${userSubscription ? userSubscription : 'non_logged_in'}`,
    })
  }, [pathname])
  return null
}

export default GTMPageTracker
